import {ossImgUrl} from '@utils/api'

export default [
    {
        title: '基础信息',
        id: 1,
        top: true,
        html:"<div>产品的基础数据，如产品名称、规格/型号、产品单位、产品条码，产品品牌、产品分类等；</div>",
        children: [
            {
                title: '产品信息',
                html:"<div style='margin:44px 0'>产品的基础数据，如产品名称、规格/型号、产品单位、产品条码，产品品牌、产品分类等；</div>",
                id: 101,
                child: true,
                children: [
                    {
                        title: '新增',
                        id: 1001,
                        html:"<p style='color: red'>注：产品名称、产品单位、规格/型号必填；</p>" +
                            "<p >产品名称：最长不超过60位<span style='color: red'>（不能重复）</span></p>" +
                            "<p>简称：最长不超过60位；</p>" +
                            "<p>规格/型号：同一产品可多个规格/型号存在、最长不超过40位<span style='color: red'>（同产品，不能重复添加）</span>；</p>" +
                            " <p>产品条码：同一产品可多个条码,条码最长不超过26位、如选择无条码则系统生成<span style='color: red'>（条码不能重复）</span>；</p>" +
                            "<p>产品单位：最长不超过20位，单位可添加<span style='color: red'>（不能重复）</span>；</p>" +
                            "<p>产品品牌：最长不超过20位，品牌可添加<span style='color: red'>（不能重复）</span>；</p>",
                        img: [`${ossImgUrl}/graphicCourse/baseInfo/1.png`]
                    },
                    {
                        title: '自定义字段',
                        id: 1002,
                        html:"<p >如有特殊字段描述，用户可自行增加字段，点击新增字段即可（注：新增字段，会作用所有仓库产品）；</p>" ,
                        img: [`${ossImgUrl}/graphicCourse/baseInfo/2.png`]
                    },
                    {
                        title: '编辑',
                        id: 1003,
                        html:"<p >除了规格/型号，产品条码，不能修改外，其它字段都可修改；</p>" ,
                    },
                    {
                        title: '作废',
                        id: 1004,
                        html:"<p >不使用的产品可作废，作废后该产品不能再进行采购，原有采购入库的信息不受影响；</p>" ,
                    }, {
                        title: '导入',
                        id: 1005,
                        html:"<p >点击导入，可下载导入模板文件，把产品信息按照模板输入，导入到管理平台；</p>" ,
                        img: [`${ossImgUrl}/graphicCourse/baseInfo/3.png`]
                    },
                    {
                        title: '规格/型号作废',
                        id: 1006,
                        html:"<p >不使用的规格/型号可作废，作废后（该规格/型号不能再进行采购，原有采购入库的产品不受影响）。</p>" ,
                    },
                ]
            },
        ]
    },
    {
      title: '产品分类',
      id: 2,
      html:"<div>维护产品的分类信息，如饮品、面食、酒水、家具、奶茶等可对产品进行分类管理；</div>",
      children: [
          {
              title: '网页端',
              id: 201,
              children: [
                  {
                      title: '产品分类维护',
                      id: 2001,
                      html:"<p><strong>新增：</strong> 新增分类（一级分类、二级分类）、新增二级分类时，必须选中一个一级分类；</p>" +
                          "<p><strong>编辑：</strong> 编辑分类（一级分类、二级分类）；</p>" +
                          "<p><strong>绑定产品：</strong> 新增分类（一级分类、二级分类）、新增二级分类时，必须选中一个一级分类；</p>" +
                          "<p><strong>修改上级分类：</strong> 二级分类可修改原关联中的上级分类；</p>" +
                          "<p><strong>删除：</strong> 分类可删除；</p>" ,
                      img: [`${ossImgUrl}/graphicCourse/classify/web/1.png`]
                  },
              ]
          },
      ]
  },
    // {
    //     title: '箱单关系',
    //     id: 3,
    //     html:"",
    //     children: [
    //         {
    //             title: '网页端',
    //             id: 301,
    //             children: [
    //                 {
    //                     title: '箱单关系维护',
    //                     id: 3001,
    //                     html:"<div> <strong>新增：</strong> 新增箱单关系，箱单关系是指一件产品与对应其它包装产品的数量关系，如一箱A产品，可拆分为10个B产品，那么查看B产品库存时，系统会展示出可由A产品拆分为B产品的数量；</div>" +
    //                         "<p> <strong>编辑：</strong> 修改箱单关系；</p>" +
    //                         "<p> <strong>删除：</strong> 删除箱单关系。</p>" ,
    //                     img: [`${ossImgUrl}/graphicCourse/packing/web/1.png`]
    //                 },
    //             ]
    //         },
    //     ]
    // },
    {
        title: '供应商信息',
        id: 4,
        html:"",
        children: [
            {
                title: '网页端',
                id: 401,
                children: [
                    {
                        title: '供应商信息维护',
                        id: 4001,
                        html:"<div> <strong>新增：</strong>新增供应商信息；</div>" +
                            "<p> <strong>编辑：</strong>点击供应商信息，进入详情，编辑供应商信息；</p>" +
                            "<p> <strong>作废：</strong>点击供应商信息，进入详情，作废供应商信息，新的采购订单中不能再选择该供应商。</p>" ,
                        img: [`${ossImgUrl}/graphicCourse/consumer/1.png`,`${ossImgUrl}/graphicCourse/consumer/2.png`]
                    },
                ]
            },
        ]
    },
    {
        title: '客户信息',
        id: 5,
        html:"",
        children: [
            {
                title: '网页端',
                id: 501,
                children: [
                    {
                        title: '客户信息维护',
                        id: 5001,
                        html:"<div> <strong>新增：</strong>新增客户信息；</div>" +
                            "<p> <strong>编辑：</strong>点击客户信息，进入详情，编辑客户信息；</p>" +
                            "<p> <strong>作废：</strong>点击客户信息，进入详情，作废客户信息，新的销售订单中不能再选择该客户。</p>" ,
                        img: [`${ossImgUrl}/graphicCourse/supplier/web/1.png`,`${ossImgUrl}/graphicCourse/supplier/web/2.png`]
                    },
                ]
            },
        ]
    },
    {
        title: '标签绑定',
        id: 6,
        html:"<div>将RFID标签与实物产品信息进行数据关联，关联后通过扫描标签二维码或者RFID感应读取RFID标签，都能快速精确的识别出该RFID标签对应的产品信息；</div>",
        children: [
            {
                title: '手持终端',
                id: 601,
                children: [
                    {
                        title: '确定产品',
                        id: 6001,
                        html:"<div> 通过按住PDA的扫描按钮、扫描产品出厂包装上的条形码（或者手动选择产品），如系统中未找到产品，那么可新增产品的基础信息；</div>" ,
                        pda:true,
                        img: [`${ossImgUrl}/graphicCourse/rfidBind/pda/1.png`,`${ossImgUrl}/graphicCourse/rfidBind/pda/2.png`]
                    },
                    {
                        title: '确定RFID标签',
                        id: 6002,
                        html:"<div> 按住PDA的扫描按钮扫描RFID标签上的二维码，确定具体标签关联产品；</div>" ,
                        pda:true,
                    },
                    {
                        title: '维护RFID标签信息',
                        id: 6003,
                        html:"<div> 如该产品需要注意过期时间，则选择关注效期选项并按产品信息录入失效日期或生产日期+保质期；</div>" ,
                        pda:true,
                        img: [`${ossImgUrl}/graphicCourse/rfidBind/pda/3.png`]
                    },
                    {
                        title: '绑定',
                        id: 6004,
                        html:"<div> 所有信息确认无误后，点击绑定，RIFD标签与产品关联成功。</div>" ,
                        pda:true,
                    },
                ]
            },
        ]
    },
    {
        title: '标签管理',
        id: 7,
        html:"<div>对出现问题的RFID标签进行维护，如RFID标签损坏、RFID标签失效日期录入错误、RFID标签绑定产品错误等情况时，进行维护变更；</div>",
        children: [
            {
                title: '网页端',
                id: 701,
                children: [
                    {
                        title: '标签信息查看、异常标签处理',
                        id: 7011,
                        html:"<div>当产品关联的RFID标签发生信息关联错误等情况时，可在标签管理进行校正；</div>" +
                            "<p> 通过标签上面的序列号，进行搜索（搜索成功，会显示出该标签的历史操作轨迹），可根据标签当前的状态，进行相应的业务操作，例如修改RFID标签记录的有效期，换绑一个新的RFID标签等。</p>" ,
                        img: [`${ossImgUrl}/graphicCourse/labelManagement/web/1.png`]
                    },
                ]
            },
            {
                title: '手持终端',
                id: 702,
                children: [
                    {
                        title: '确定RFID标签',
                        id: 7021,
                        pda:true,
                        html:"<div>通过按住PDA的扫描按钮，扫描RFID标签，或输入RFID标签上面的标签序列号，通过以上方式都能找到我们需要操作的RFID标签；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/labelManagement/pda/1.png`,`${ossImgUrl}/graphicCourse/labelManagement/pda/2.png`]
                    },
                    {
                        title: '操作',
                        id: 7022,
                        pda:true,
                        html:"<div>确定标签后，会显示出当前RFID标签的历史操作轨迹及当前的状态，可根据状态进行相应的操作（标签换绑、标签解绑、效期修改、其他入库、其他出库）。</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/labelManagement/pda/3.png`]
                    },
                ]
            },
        ]
    },
    {
        title: '采购入库',
        id: 8,
        html:"",
        children: [
            {
                title: '网页端',
                id: 801,
                children: [
                    {
                        title: '新增',
                        id: 8011,
                        html:"<div>（采购管理-采购订单：待入库---点击新增、选择供应商、添加采购产品，输入数量、价格，提交订单）；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/procurement/web/1.png`,`${ossImgUrl}/graphicCourse/procurement/web/2.png`]
                    },
                    {
                        title: '编辑/删除/打印',
                        id: 8012,
                        html:"<div>点击订单、进入订单详情，可修改/删除/打印订单；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/procurement/web/3.png`,`${ossImgUrl}/graphicCourse/procurement/web/4.png`]
                    },
                    {
                        title: '已入库列表',
                        id: 8013,
                        html:"<div>是完成的采购订单，数据记录、可通过不同的条件进行筛选；</div>" ,
                        img: ''
                    }
                ]
            },
            {
                title: '手持终端',
                id: 802,
                children: [
                    {
                        title: '新增',
                        id: 8021,
                        pda:true,
                        html:"<div>采购管理-采购订单：点击右上角新增图标（+），选择供应商、添加采购产品，输入数量、价格，提交订单）；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/procurement/pda/1.png`,`${ossImgUrl}/graphicCourse/procurement/pda/2.png`,`${ossImgUrl}/graphicCourse/procurement/pda/3.png`,`${ossImgUrl}/graphicCourse/procurement/pda/4.png`]
                    },
                    {
                        title: '扫描入库',
                        id: 8022,
                        pda:true,
                        html:"<div>使用移动PDA，打开纸盒APP，找到对应的菜单，选择相应业务订单，点击扫描入库、扫描实物产品，核对数据，确认无误，完成入库；</div>" +
                        "<div>点击扫描入库后、按住PDA的扫描开关，进行扫描；</div>",
                        img: [`${ossImgUrl}/graphicCourse/procurement/pda/5.png`,`${ossImgUrl}/graphicCourse/procurement/pda/6.png`]
                    },
                    {
                        title: '扫描完成',
                        id: 8023,
                        pda:true,
                        html:"<div>核对数据，数据无误，点击确认入库，采购入库完成。</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/procurement/pda/7.png`]
                    },
                ]
            },
        ]
    },
    {
        title: '采退出库',
        id: 9,
        html:"<div>采退出库：是指本企业向供应商采购的货物，因某原因退还给供应商：</div>",
        children: [
            {
                title: '网页端',
                id: 901,
                children: [
                    {
                        title: '新增',
                        id: 9011,
                        html:"<div>待出库模块中，点击新增按钮：选择供应商，添加产品，填写数量，金额，保存订单即创建完成；也可在移动PDA上新增该业务订单；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/buyOut/web/1.png`,`${ossImgUrl}/graphicCourse/buyOut/web/2.png`]
                    },
                    {
                        title: '编辑/删除/打印',
                        id: 9012,
                        html:"<div>点击订单、进入订单详情，可修改/删除/打印订单；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/buyOut/web/3.png`,`${ossImgUrl}/graphicCourse/buyOut/web/4.png`]
                    },
                    {
                        title: '已出库列表',
                        id: 9013,
                        html:"<div>是完成的采退订单，数据记录、可通过不同的条件进行筛选；</div>" ,
                        img: ''
                    }
                ]
            },
            {
                title: '手持终端',
                id: 902,
                children: [
                    {
                        title: '订单列表',
                        id: 9021,
                        pda:true,
                        html:"<div>PDA打开采退出库模块，可查看订单任务；</div>" ,
                    },
                    {
                        title: '新增',
                        id: 9022,
                        pda:true,
                        html:"<div>PDA打开采退出库模块，进入到订单列表页，点击右上角新增，选择采退对象供应商，添加产品，按住RIFD开关，扫描产品实时显示扫描产品信息、（或者手动添加选择产品）核对信息、数量、价格无误，点击确认、即可完成订单创建；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/withdrawal/pda/1.png`,`${ossImgUrl}/graphicCourse/withdrawal/pda/2.png`,`${ossImgUrl}/graphicCourse/withdrawal/pda/3.png`]
                    },
                    {
                        title: '编辑',
                        id: 9023,
                        pda:true,
                        html:"<div>点击订单、进入订单产品列表页，点击编辑、（可编辑产品、数量、价格）；</div>" ,
                    },
                    {
                        title: '删除',
                        id: 9024,
                        pda:true,
                        html:"<div>PDA打开采退出库模块，进入到订单列表页，想删除某个订单，订单左滑、可删除订单。</div>" ,
                    },
                    {
                        title: '扫描出库',
                        id: 9025,
                        pda:true,
                        html:"<div>选择订单、进入到订单详情页，点击扫描出库，扫描产品，完成出库；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/withdrawal/pda/4.png`]
                    },
                ]
            },
        ]
    },
    {
        title: '销售出库',
        id: 10,
        html:"<div>销售出库：是指销售出库，售卖给客户；</div>",
        children: [
            {
                title: '网页端',
                id: 1001,
                children: [
                    {
                        title: '新增',
                        id: 10011,
                        html:"<div>待出库模块中，点击新增按钮：选择客户，添加产品，填写数量，金额，保存订单即创建完成；也可在移动PDA上新增该业务订单；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/sale/web/1.png`,`${ossImgUrl}/graphicCourse/sale/web/2.png`]
                    },
                    {
                        title: '编辑/删除/打印',
                        id: 10012,
                        html:"<div>点击订单、进入订单详情，可修改/删除/打印订单；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/sale/web/3.png`,`${ossImgUrl}/graphicCourse/sale/web/4.png`]
                    },
                    {
                        title: '已出库列表',
                        id: 10013,
                        html:"<div>是完成的销售订单，数据记录、可通过不同的条件进行筛选；</div>" ,
                        img: ''
                    }
                ]
            },
            {
                title: '手持终端',
                id: 1002,
                children: [
                    {
                        title: '订单列表',
                        id: 10021,
                        pda:true,
                        html:"<div>PDA打开销售出库模块，可查看订单任务；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/sale/pda/3.png`]
                    },
                    {
                        title: '新增',
                        id: 10022,
                        pda:true,
                        html:"<div>PDA打开销售出库模块，进入到订单列表页，点击右上角新增，选择销售对象客户，添加产品，按住RIFD开关，扫描产品实时显示扫描产品信息、（或者手动添加选择产品）核对信息、数量、价格无误，点击确认、完成订单创建；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/sale/pda/1.png`,`${ossImgUrl}/graphicCourse/sale/pda/2.png`]
                    },
                    {
                        title: '编辑',
                        id: 10023,
                        pda:true,
                        html:"<div>点击订单、进入订单产品列表页，点击编辑、（可编辑产品、数量、价格）；</div>" ,
                    },
                    {
                        title: '删除',
                        id: 10024,
                        pda:true,
                        html:"<div>PDA打开销售出库模块，进入到订单列表页，想删除某个订单，订单左滑、即可删除订单；</div>" ,
                    },
                    {
                        title: '扫描出库',
                        id: 10025,
                        pda:true,
                        html:"<div>PDA打开销售出库模块，进入到订单列表页，选择需要出库的订单，点击进入订单详情页，点击扫描出库、按住RIFD开关，扫描产品实时显示扫描产品信息、核对信息无误，点击确认出库即可完成订单。</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/sale/pda/4.png`]
                    },
                ]
            },
        ]
    },
    {
        title: '销退入库',
        id: 11,
        html:"<div>销退入库：是指销售出去的产品，被客户退回；</div>",
        children: [
            {
                title: '网页端',
                id: 1101,
                children: [
                    {
                        title: '新增',
                        id: 11011,
                        html:"<div>待入库模块中，点击新增按钮：选择客户，添加产品，填写数量，金额，保存订单即创建完成；也可在移动PDA上新增该业务订单；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/saleOut/web/1.png`,`${ossImgUrl}/graphicCourse/saleOut/web/2.png`]
                    },
                    {
                        title: '编辑/删除/打印',
                        id: 11012,
                        html:"<div>点击订单、进入订单详情，可修改/删除/打印订单；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/saleOut/web/3.png`,`${ossImgUrl}/graphicCourse/saleOut/web/4.png`]
                    },
                    {
                        title: '已入库列表',
                        id: 11013,
                        html:"<div>是完成的销退订单，数据记录、可通过不同的条件进行筛选；</div>" ,
                        img: ''
                    }
                ]
            },
            {
                title: '手持终端',
                id: 1102,
                children: [
                    {
                        title: '订单列表',
                        id: 11021,
                        pda:true,
                        html:"<div>PDA打开销退入库模块，可查看订单任务；</div>" ,
                    },
                    {
                        title: '新增',
                        id: 11022,
                        pda:true,
                        html:"<div>PDA打开销退入库模块，进入到订单列表页，点击右上角新增，选择销退客户，添加产品，按住RIFD开关，扫描产品实时显示扫描产品信息、（或者手动添加选择产品）核对信息、数量、价格无误，点击保存订单、完成订单创建；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/saleOut/pda/1.png`,`${ossImgUrl}/graphicCourse/saleOut/pda/2.png`,`${ossImgUrl}/graphicCourse/saleOut/pda/3.png`]
                    },
                    {
                        title: '编辑',
                        id: 11023,
                        pda:true,
                        html:"<div>点击订单、进入订单产品列表页，点击编辑、（可编辑产品、数量、价格）；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/saleOut/pda/4.png`]
                    },
                    {
                        title: '删除',
                        id: 11024,
                        pda:true,
                        html:"<div>PDA打开销退入库模块，进入到订单列表页，想删除某个订单，订单左滑、可删除订单；</div>" ,
                    },
                    {
                        title: '扫描入库',
                        id: 11025,
                        pda:true,
                        html:"<div>PDA打开销退入库模块，进入到订单列表页，选择需要入库的订单，点击进入订单详情页，点击扫描入库、按住RIFD开关，扫描产品实时显示扫描产品信息、核对信息无误，点击确认入库、入库完成；</div>" ,
                    },
                ]
            },
        ]
    },
    {
        title: '调拨入库',
        id: 12,
        html:"",
        children: [
            {
                title: '网页端',
                id: 1201,
                children: [
                    {
                        title: '新增',
                        id: 12011,
                        html:"<div>（入库管理-调拨入库：待入库模块中，点击新增按钮：选择出库仓库，添加产品，填写数量、保存订单、订单创建成功）；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/allot/web/1.png`,`${ossImgUrl}/graphicCourse/allot/web/2.png`]
                    },
                    {
                        title: '编辑/删除/打印',
                        id: 12012,
                        html:"<div>根据订单状态不同，展示操作项不同、点击订单，进入订单详情；</div>" +
                            "<div>待确认状态：订单可撤销；</div>"+
                            "<div>已撤销状态：订单可编辑/删除；</div>" +
                            "<div>已拒绝状态：订单可编辑/删除；</div>",
                        img: [`${ossImgUrl}/graphicCourse/allot/web/3.png`,`${ossImgUrl}/graphicCourse/allot/web/4.png`]
                    },
                    {
                        title: '发送消息',
                        id: 12013,
                        html:"<div>点击订单，进入订单详情；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/allot/web/5.png`,`${ossImgUrl}/graphicCourse/allot/web/6.png`]
                    },
                    {
                        title: '已入库列表',
                        id: 12014,
                        html:"<div>是完成的调拨订单，数据记录、可通过不同的条件进行筛选；</div>" ,
                        img: ''
                    },
                ]
            },
            {
                title: '手持终端',
                id: 1202,
                children: [
                    {
                        title: '新增',
                        id: 12021,
                        pda:true,
                        html:"<div>入库管理-调拨入库：点击右上角新增图标（+），选择出库仓库、添加产品，输入数量、提交订单；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/allot/pda/1.png`,`${ossImgUrl}/graphicCourse/allot/pda/2.png`]

                    },
                    {
                        title: '扫描入库',
                        id: 12022,
                        pda:true,
                        html:"<div>使用PDA打开订单，扫描产品，确认数据信息无误，确认入库；</div>" ,
                    },
                ]
            },
        ]
    },
    {
        title: '调拨出库',
        id: 13,
        html:"<div>（同调拨入库，操作方式一样）</div>",
        children: [
            {
                title: '网页端',
                id: 1301,
                children: [
                    {
                        title: '新增',
                        id: 13011,
                        html:"<div>出库管理-调拨出库：待出库模块中，点击新增按钮：选择收货仓库，添加产品，填写数量，保存订单、订单创建完成；</div>" ,
                    },
                    {
                        title: '撤销',
                        id: 13012,
                        html:"<div>订单可撤销，订单撤销后，对方仓库不可见该订单；</div>" ,
                    },
                    {
                        title: '编辑/删除/打印',
                        id: 13013,
                        html:"<div>根据订单状态不同，展示操作项不同、点击订单，进入订单详情；</div>" +
                            "<div>撤销状态、已拒绝状态，订单可编辑，修改订单产品，数量等；</div>"+
                            "<div>撤销状态、已拒绝状态，订单可删除；</div>" +
                            "<div>击订单、进入详情，可打印订单信息；</div>",
                    },
                    {
                        title: '已出库列表',
                        id: 13014,
                        html:"<div>是完成的调拨订单，数据记录、可通过不同的条件进行筛选；</div>" ,
                        img: ''
                    },
                ]
            },
            {
                title: '手持终端',
                id: 1302,
                children: [
                    {
                        title: '新增',
                        id: 13021,
                        pda:true,
                        html:"<div>出库管理-调拨出库：点击右上角新增图标（+），选择收货仓库、添加产品，输入数量、提交订单；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/allot/pda/3.png`]
                    },
                    {
                        title: '发送消息',
                        id: 13022,
                        pda:true,
                        html:"<div>发送消息至对方仓库负责人；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/allot/pda/4.png`]
                    },
                    {
                        title: '扫描出库',
                        id: 13023,
                        pda:true,
                        html:"<div>使用PDA打开订单，扫描产品，确认数据信息无误，并出库；（如找不到需出库产品，可使用辅助寻货）</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/allot/pda/5.png`]
                    },
                ]
            },
        ]
    },
    {
        title: '报损出库',
        id: 14,
        html:"<div>报损出库：是指产品损坏，不能正常销售及退货、堆积在库存又无用的产品；</div>",
        children: [
            {
                title: '手持终端',
                id: 1401,
                children: [
                    {
                        title: '新增',
                        id: 14011,
                        pda:true,
                        html:"<div>PDA打开报损出库模块，进入新增页面，添加产品，按住RIFD开关，扫描产品实时显示扫描产品信息、核对信息、数量、价格无误，点击确认出库、完成出库；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/breakage/pda/1.png`,`${ossImgUrl}/graphicCourse/breakage/pda/2.png`]
                    },
                ]
            },
        ]
    },
    {
        title: '其他入库',
        id: 15,
        html:"<div>其他入库：是指非常规业务上的事情，但产品确实需要入库，则可通过其他入库；</div>",
        children: [
            {
                title: '手持终端',
                id: 1501,
                children: [
                    {
                        title: '新增',
                        id: 15011,
                        pda:true,
                        html:"<div>PDA打开其他入库模块，进入新增页面，添加产品，按住RIFD开关，扫描产品实时显示扫描产品信息、核对信息、数量、价格无误，点击确认、点击确认入库、即可完成订单；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/otherIn/pda/1.png`,`${ossImgUrl}/graphicCourse/otherIn/pda/2.png`]
                    },

                ]
            },
        ]
    },
    {
        title: '其他出库',
        id: 16,
        html:"<div>其他出库：是指非常规业务上的事情，但产品确实需要出库，则可通过其他出库；</div>",
        children: [
            {
                title: '手持终端',
                id: 1601,
                children: [
                    {
                        title: '新增',
                        id: 16011,
                        pda:true,
                        html:"<div>PDA打开其他出库模块，进入新增页面，添加产品，按住RIFD开关，扫描产品实时显示扫描产品信息、核对信息、数量、价格无误，点击确认出库、完成出库；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/otherOut/pda/1.png`,`${ossImgUrl}/graphicCourse/otherOut/pda/2.png`]
                    },

                ]
            },
        ]
    },
    {
        title: '期初入库',
        id: 17,
        html:"",
        children: [
            {
                title: '手持终端',
                id: 1701,
                children: [
                    {
                        title: '新增',
                        id: 17011,
                        pda:true,
                        html:"<div>更多用于企业系统更换，现有产品库存信息入库至新的管理系统中；</div>" +
                        "<div>规则：当该企业仓库中，还没有产生过库存时，在PDA首页，会浮现期初入库按钮，点击期初入库，扫描产品，完成入库；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/beginning/pda/1.png`,`${ossImgUrl}/graphicCourse/beginning/pda/2.png`]
                    },

                ]
            },
        ]
    },
    {
        title: '库存管理',
        id: 18,
        html:"<div>所有在库数据统计分析看板，记录有所有在库产品数量、金额等合计，以及在库产品的类型分布、在库产品排名等不同维度的信息统计，可通过仓库、时间等条件进行数据过滤；</div>",
        children: [
            {
                title: '网页端',
                id: 1801,
                children: [
                    {
                        title: '库存列表',
                        id: 18011,
                        html:"<div>可查看在库的库存情况，产品数量、产品金额，预估可销售周期（系统根据日常出库量进行的计算，计算出现有库存大概能满足几天的需求）等信息。点击列表可查看具体产品的库存详情；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/inventory/web/1.png`]
                    },
                    {
                        title: '库存预警',
                        id: 18012,
                        html:"<div>库存快不足以支撑正常的销售业务开展时，系统提示用户补货，系统默认规则为可销售周期（系统通过近期销售量预估的可销售周期）小于等于7天时预警，用户可修改预估销售周期的判断时间，也可配置为产品的最小库存进行预警；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/inventory/web/3.png`]
                    },

                    {
                        title: '效期预警',
                        id: 18013,
                        html:"<div>产品在入库时，对需要管理效期的产品，录入失效日期，这个日期是跟随每件货物的RFID标签，所以我们能精准的预警出是哪一件产品快过期了，引导用户进行提前处理；系统默认的规则为当失效日期距离当前日期小于等于60天时预警，用户可修改预警规则，也可针对产品单独配置规则；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/inventory/web/2.png`,`${ossImgUrl}/graphicCourse/inventory/web/4.png`]
                    },
                    {
                        title: '滞销库存',
                        id: 18014,
                        html:"<div>当产品入库后，根据产品的在库时间进行预警，系统默认规则为在库时间大于等于180天进行预警，用户可修改预警规则，也可针对产品单独配置规则；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/inventory/web/5.png`]
                    },
                ]
            },
        ]
    },
    {
        title: '流水记录',
        id: 19,
        html:"",
        children: [
            {
                title: '网页端',
                id: 1901,
                children: [
                    {
                        title: '流水记录',
                        id: 19011,
                        html:"<div>仓库中的产品变更流水明细，可通过多个维度条件进行数据过滤筛选；</div>" ,
                    },
                    {
                        title: '报表导出',
                        id: 19012,
                        html:"<div>点击导出按钮，可自定义时间、产品等维度下载详细EXCEL文件至本地电脑。</div>" ,
                    },
                ]
            },
        ]
    },
    {
        title: '库存盘点',
        id: 20,
        html:"",
        children: [
            {
                title: '网页端',
                id: 2001,
                children: [
                    {
                        title: '新增',
                        id: 20011,
                        html:"<div>库存管理-库存盘点：盘点列表、点击新增，新增盘点任务，选择盘点方式</div>"
                        +"<div>计划盘点（则是按照计划中的产品进行盘点，非计划中的产品不进入盘点任务中），</div>"
                        +"<div>仓库全盘（则是在仓库中实盘到有那些产品，就是那些产品）</div>" ,
                        img:[`${ossImgUrl}/graphicCourse/stockTake/web/1.png`,`${ossImgUrl}/graphicCourse/stockTake/web/2.png`]
                    },
                    {
                        title: '取消',
                        id: 20012,
                        html:"<div>点击订单，进入订单详情页，可取消盘点订单；</div>" ,
                        img:[`${ossImgUrl}/graphicCourse/stockTake/web/3.png`,`${ossImgUrl}/graphicCourse/stockTake/web/4.png`]
                    },
                    {
                        title: '编辑/删除',
                        id: 20013,
                        html:"<div>已取消状态订单，可编辑/删除订单</div>"
                        +"<div>任务创建后，会同步下发至移动PDA，可取消、可修改、可删除，</div>"
                        +"<div><span style='color:red'>注意：</span>盘点是不会锁定库存的，盘点期间可正常开展其它业务，并不会影响盘点（因为我们能够实时获取到盘点中，操作的业务数据）</div>",
                        img:[`${ossImgUrl}/graphicCourse/stockTake/web/5.png`]
                    },
                    {
                        title: '盘点记录',
                        id: 20014,
                        html:"<div>是完成的盘点订单，数据记录、可通过不同的条件进行筛选</div>"
                    },
                ]
            },
            {
                title: '手持终端',
                id: 2002,
                children: [
                    {
                        title: '新增',
                        id: 20021,
                        pda:true,
                        html:"<div>点击菜单库存盘点，点击右上角新增+，新增盘点</div>",
                        img:[`${ossImgUrl}/graphicCourse/stockTake/pda/1.png`,`${ossImgUrl}/graphicCourse/stockTake/pda/2.png`,`${ossImgUrl}/graphicCourse/stockTake/pda/3.png`]
                    },
                    {
                        title: '扫描盘点',
                        id: 20022,
                        pda:true,
                        html:"<div>点击订单，进入订单详情，点击开始盘点</div>" ,
                        img:[`${ossImgUrl}/graphicCourse/stockTake/pda/4.png`,`${ossImgUrl}/graphicCourse/stockTake/pda/5.png`]
                    },
                    {
                        title: '扫描完成',
                        id: 20023,
                        pda:true,
                        html:"<div>扫描完成，核对数据无误，点击确认盘点完成，该盘点任务完成</div>",
                        img:[`${ossImgUrl}/graphicCourse/stockTake/pda/6.png`]
                    },
                ]
            },
        ]
    },
    {
        title: '统计报表',
        id: 21,
        html:"",
        children: [
            {
                title: '网页端',
                id: 2101,
                children: [
                    {
                        title: '数据统计',
                        id: 21011,
                        html:"<div>所有出入库业务的数据统计分析看板，记录有所有出入库订单数量、金额、出入库产品的类型分布等不同维度的信息统计，可通过仓库，时间等条件进行数据过滤；</div>"
                        +"<div>报表导出：可按不同维度查询数据，导出报表可下载详细EXCEL文件至本地电脑。</div>",
                    },
                    {
                        title: '销售毛利',
                        id: 21012,
                        html:"<div>所有销售出库业务的数据统计分析看板，记录有所有销售出库订单数量、成本金额、销售金额、毛利金额等数据，以及销售出库产品的类型分布等不同维度的信息统计，可通过仓库，时间等条件，进行数据过滤；</div>"
                        +"<div >报表导出：可按不同维度查询数据，导出报表可下载详细EXCEL文件至本地电脑。</div>"
                        +"<div><span style='color:red'>注意：</span>统计数据为销售出库与其他出库两个业务的所有数据。</div>",
                    },
                ]
            },
        ]
    },
    {
        title: '辅助寻货',
        id: 22,
        html:"",
        children: [
            {
                title: '手持终端',
                id: 2201,
                children: [
                    {
                        title: '出库环节',
                        id: 22011,
                        pda:true,
                        html:"<div>当一时无法找到产品存放位置时，可使用辅助寻货功能，快捷寻找到产品；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/getProduct/pda/1.png`]
                    },
                    {
                        title: '首页',
                        id: 22012,
                        pda:true,
                        html:"<div>扫描产品时，扫描到多个产品包装，无法快捷找到具体某箱货物时，可使用辅助寻货，快捷寻找到产品；</div>" ,
                        img: [`${ossImgUrl}/graphicCourse/getProduct/pda/2.png`,`${ossImgUrl}/graphicCourse/getProduct/pda/3.png`,`${ossImgUrl}/graphicCourse/getProduct/pda/4.png`,`${ossImgUrl}/graphicCourse/getProduct/pda/5.png`,`${ossImgUrl}/graphicCourse/getProduct/pda/6.png`]
                    },
                    {
                        title: '效期预警',
                        id: 22013,
                        pda:true,
                        html: "<div>当某箱产品效期已经接近过期，无法快捷找到具体货物时，可使用辅助寻货，快捷找到临近过期产品；</div>",
                        img: [`${ossImgUrl}/graphicCourse/getProduct/pda/7.png`,`${ossImgUrl}/graphicCourse/getProduct/pda/8.png`,`${ossImgUrl}/graphicCourse/getProduct/pda/9.png`,`${ossImgUrl}/graphicCourse/getProduct/pda/10.png`,`${ossImgUrl}/graphicCourse/getProduct/pda/11.png`]
                    },
                ]
            },
        ]
    },
    {
        title: '系统设置',
        id: 23,
        html:"",
        children: [
            {
                title: '网页端',
                id: 2301,
                children: [
                    {
                        title: '个人信息',
                        id: 23011,
                        html:"<div>当前登录账户的账户信息，可进行绑定手机号、修改登录密码、变更绑定手机号功能。</div>",
                        img:[`${ossImgUrl}/graphicCourse/setting/web/1.png`]
                    },
                    {
                        title: '仓库管理',
                        id: 23012,
                        html:"<div>对企业的仓库信息进行增加、编辑与禁用，禁用后的仓库不会出其他业务中，但历史数据统计不受影响；</div>"
                        +"<div ><span>注意：</span>仓库是需先完善的信息，后续所有业务都依赖于具体仓库。</div>" ,
                        img:[`${ossImgUrl}/graphicCourse/setting/web/2.png`]
                    },
                    {
                        title: '角色管理',
                        id: 23013,
                        html:"<div>角色代表不同的权限层级，例如仓库负责人，门店销售，入库检查人员等角色。建立不同的角色，并给予角色相应权限，若想要达到不同用户拥有不同功能权限的目的，在用户管理功能中对具体用户分配对应角色即可。</div>",
                        img:[`${ossImgUrl}/graphicCourse/setting/web/3.png`]
                    },
                    {
                        title: '用户管理',
                        id: 23014,
                        html:"<div>每个企业均可配置多名用户共同管理仓库，在此功能中可以管理具体子账户信息、功能权限与用户关联的仓库，用户只能查看已关联账户的数据，及针对该仓库进行操作。用户功能权限需使用角色管理功能进行具体功能权限分配，在用户管理中直接分配对应角色即可拥有对应功能权限。</div>"
                        ,img:[`${ossImgUrl}/graphicCourse/setting/web/4.png`]
                    },
                    {
                        title: '货架管理',
                        id: 23015,
                        html: "<div>如仓库中，采用了货架等更明确的管理模式，可在仓库中创建货架。</div>" +
                            "<div>货架新增、编辑与禁用，禁用后的货架不能在往上进行货品上架，但本身在货架上的物品不会产生任何影响，可正常下架，出库等。</div>" +
                            "<div>新增：系统管理--货架管理--添加货架。</div>" +
                            "<div>详情：货架列表中、点击货架，可查看详情，能看到每个货位上存放的产品信息。</div>" +
                            "<div>编辑：货架详情页面、点击编辑，可修改货架名称，货位可启用、禁用。</div>"
                        ,
                        img: [`${ossImgUrl}/graphicCourse/shelf/WEB/1.png`, `${ossImgUrl}/graphicCourse/shelf/WEB/2.png`, `${ossImgUrl}/graphicCourse/shelf/WEB/3.png`, `${ossImgUrl}/graphicCourse/shelf/WEB/4.png`, `${ossImgUrl}/graphicCourse/shelf/WEB/5.png`]
                    },
                ]
            },
        ]
    },
    {
        title: '产品拆零',
        id: 24,
        html: "",
        children: [
            {
                title: '网页端',
                id: 2401,
                children: [
                    {
                        title: '拆零概述',
                        id: 24011,
                        html: "<div>拆零是指，产品存在以零散的方式发生业务，如：采购时是按箱采购，销售出库时是拆开箱子、按瓶进行销售等，则需要对数量单位、金额进行转换，（如：1箱=10瓶）便于成本核算及使用者更好的进行二者之间的区分。</div>" +
                            "<div>拆零后的零散产品可以选择不使用RFID标签进行管理。</div>",
                        img: [`${ossImgUrl}/graphicCourse/setting/web/1.png`]
                    },
                    {
                        title: '如何维护拆零关系',
                        id: 24012,
                        html: "<div>打开产品信息--基础信息，选择产品点击进入产品详情，点击编辑按钮，进入产品信息编辑界面、找到规格/型号字段，点击拆零、即可维护拆零关系。</div>",
                        img: [`${ossImgUrl}/graphicCourse/scattered/WEB/1.png`, `${ossImgUrl}/graphicCourse/scattered/WEB/2.png`, `${ossImgUrl}/graphicCourse/scattered/WEB/3.png`, `${ossImgUrl}/graphicCourse/scattered/WEB/4.png`]
                    },
                    {
                        title: '业务应用',
                        id: 24013,
                        html: "<p >1、适用于平台所有业务：采购、采退、销售、销退、调拨等。</p>" +
                            "<p>2、零散产品使用方式：</p>" +
                            "<p>A、在创建业务订单时，选择有标记（零）的产品。</p>" +
                            "<p>B、在业务入库时，带有标记（零）的产品，输入数量、金额即可无需扫描。</p>" +
                            "<p>C、在业务出库时，带有标记（零）的产品，输入数量（该产品已出箱零散数量必须大于等于当前输入数量）、金额即可无需扫描。</p>" +
                            "<p>3、整装产品，直接扫描出库；零散产品，需先出箱后出库。</p>",
                    },
                    {
                        title: '用户管理',
                        id: 23014,
                        html: "<div>每个企业均可配置多名用户共同管理仓库，在此功能中可以管理具体子账户信息、功能权限与用户关联的仓库，用户只能查看已关联账户的数据，及针对该仓库进行操作。用户功能权限需使用角色管理功能进行具体功能权限分配，在用户管理中直接分配对应角色即可拥有对应功能权限。</div>"
                        , img: [`${ossImgUrl}/graphicCourse/setting/web/4.png`]
                    },
                ]
            },
            {
                title: '手持终端',
                id: 2402,
                children: [
                    {
                        title: '拆零出箱操作介绍',
                        id: 24014,
                        pda: true,
                        html: "<p >产品从整装变成零散，且已出箱的产品不使用RFID标签管理（如：1箱=10瓶，拆零出箱5瓶、已出箱的5瓶则可直接进行出库、未出箱的5瓶则需要出箱后，才能出库）。</p>" +
                            "<p >1、打开PDA，打开标签管理--拆零出箱菜单，扫描产品标签二维码或包装上出厂条码，识别产品信息，也可手动选择产品。</p>" +
                            '<p>2、确定需要拆零出箱产品后，扫描出箱产品的RFID标签（同产品仓库中较多，扫描箱体上的RFID标签，便于确认具体拆箱情况）。</p>' +
                            '<p>3、确认具体出箱的信息后，输入需出箱数量，完成出箱。（标签序列号后面，显示可用库存，代表该箱中当前可出箱的产品数量，标签序列号，对应可用库存数量没有的，可左滑删除该标签）。</p>',
                        img: [`${ossImgUrl}/graphicCourse/scattered/out/1.png`, `${ossImgUrl}/graphicCourse/scattered/out/2.png`, `${ossImgUrl}/graphicCourse/scattered/out/3.png`, `${ossImgUrl}/graphicCourse/scattered/out/4.png`]
                    },
                    {
                        title: '拆零入箱操作介绍',
                        id: 24015,
                        pda: true,
                        html: "<p >在库已出箱的零散产品，进行入箱操作，入箱后采用RFID标签统一管理，当入箱数量达到整装标准时，产品从零散变成整装（如：入箱的零散产品数量等于10瓶，则变为1箱）。</p>" +
                            "<p >1、打开PDA，打开标签管理--拆零入箱菜单，扫描产品标签二维码或包装上出厂条码，识别产品信息，也可手动选择产品。</p>" +
                            '<p>2、确定需要拆零入箱产品后，扫描入箱产品的RFID标签（同产品仓库中较多，扫描箱体上的RFID标签，便于确认具体入箱情况）。</p>' +
                            '<p>3、确认具体入箱的信息后，输入需入箱数量，完成入箱。\n' +
                            '左上角显示可入箱数量，代表当前该仓库中，本产品已出箱数量，只有已出箱产品，才可入箱，（标签序列号后面，显示可入箱数量，代表该箱中当前还可以入箱的产品数量，标签序列号，对应可入箱数量没有的，可左滑删除该标签）。</p>',
                        img: [`${ossImgUrl}/graphicCourse/scattered/in/1.png`, `${ossImgUrl}/graphicCourse/scattered/in/2.png`, `${ossImgUrl}/graphicCourse/scattered/in/3.png`, `${ossImgUrl}/graphicCourse/scattered/in/4.png`],
                    }
                ]
            }
        ]
    },
    {
        title: '货品上下架',
        id: 25,
        html: "",
        children: [
            {
                title: '手持终端',
                id: 2502,
                children: [
                    {
                        title: '货品上架',
                        id: 25011,
                        pda: true,
                        html: "<p >货品上架：把仓库中的未存放在货架中的货物，存放至货架。</p>" +
                            "<p >打开货品上架菜单，先扫描上架产品。</p>" +
                            '<p>确认上架产品后，选择该产品需要上至那个货架。</p>' +
                            '<p>确认上架，产品存放上货架，上架完成。</p>',
                        img: [`${ossImgUrl}/graphicCourse/shelf/IN/1.png`, `${ossImgUrl}/graphicCourse/shelf/IN/2.png`, `${ossImgUrl}/graphicCourse/shelf/IN/3.png`, `${ossImgUrl}/graphicCourse/shelf/IN/4.png`, `${ossImgUrl}/graphicCourse/shelf/IN/5.png`]
                    },
                    {
                        title: '货品下架',
                        id: 25015,
                        pda: true,
                        html: "<p >货品下架：取出现存放在货架中的产品。</p>" +
                            "<p >先扫描货架编号，也可手动选择货架。</p>" +
                            '<p>确认货架后，扫描需下架的产品。</p>' +
                            '<p>确认下架，产品从货架上取出，下架完成。</p>',
                        img: [`${ossImgUrl}/graphicCourse/shelf/OUT/1.png`, `${ossImgUrl}/graphicCourse/shelf/OUT/2.png`, `${ossImgUrl}/graphicCourse/shelf/OUT/3.png`, `${ossImgUrl}/graphicCourse/shelf/OUT/4.png`],
                    }
                ]
            }
        ]
    },
]